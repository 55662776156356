@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}
svg > rect:first-child {
  fill-opacity: 0 !important;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
.search-label {
  float: right;
}
.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

// login pag UI css
.captcha {
  margin: 0 auto;
  width: 100%;
  margin-bottom: 1rem;
}

@media screen and (max-width: 480px) {
  .captcha {
      width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .captcha {
      width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .captcha {
      width: 99%;
      // transform:"scale(0.90)" !important; 
      // transform-origin: 0px;
      // transformOrigin:"0 0"
  }
}

@media screen and (max-width: 375px) {
  .captcha {
      width: 100%;
      float: left;
  }
}
.divider{
  border-bottom: 1px solid #e0e0e0;
  
}
@media (min-width: 375px) and (max-width: 667px) {
  .dropdown-menu.show {
      top: 5% !important;
  }
  
}

// Bredcrum css

section {
  /* used just to separate different styles */
  border-bottom: 1px solid #e6e6e6;
  // padding: 2em 0;
  padding: 0.5em 0;
}
// section h4 {
//   width: 90%;
//   // max-width: 760px;
//   margin: 0 auto 2em;
//   color: #2c3f4c;
//   font-size: 1rem;
//   font-weight: 700;
//   text-align: center;
// }
@media only screen and (min-width: 1170px) {
  section {
    padding: 1em 0;
  }
  
}

/* -------------------------------- 

Basic Style

-------------------------------- */
.cd-breadcrumb, .cd-multi-steps {
  width: 90%;
  // max-width: 768px;
  padding: 0.5em 1em;
  margin: 0.5em auto;
  background-color: #edeff0;
  border-radius: .25em;
}
.cd-breadcrumb::after, .cd-multi-steps::after {
  clear: both;
  content: "";
  display: table;
}
.cd-breadcrumb li, .cd-multi-steps li {
  display: inline-block;
  float: left;
  margin: 0.5em 0;
}
.cd-breadcrumb li::after, .cd-multi-steps li::after {
  /* this is the separator between items */
  display: inline-block;
  content: '\00bb';
  margin: 0 .6em;
  color: #959fa5;
}
.cd-breadcrumb li:last-of-type::after, .cd-multi-steps li:last-of-type::after {
  /* hide separator after the last item */
  display: none;
}
.cd-breadcrumb li > *, .cd-multi-steps li > * {
  /* single step */
  display: inline-block;
  font-size: 1rem;
  color: #2c3f4c;
}
.cd-breadcrumb li.current > *, .cd-multi-steps li.current > * {
  /* selected step */
  color: #96c03d;
}
.no-touch .cd-breadcrumb a:hover, .no-touch .cd-multi-steps a:hover {
  /* steps already visited */
  color: #96c03d;
}
.cd-breadcrumb.custom-separator li::after, .cd-multi-steps.custom-separator li::after {
  /* replace the default separator with a custom icon */
  content: '';
  height: 16px;
  width: 16px;
  background: url(https://codyhouse.co/demo/breadcrumbs-multi-steps-indicator/img/cd-custom-separator.svg) no-repeat center center;
  vertical-align: middle;
}

.trade-data{
  width: 100%;
  padding: 10px 10px;
  font-size: 1rem;
}

/* -------------------------------- 

Triangle breadcrumb

-------------------------------- */
@media only screen and (min-width: 768px) {
  .cd-breadcrumb.triangle {
    /* reset basic style */
    background-color: transparent;
    padding: 0;
  }
  .cd-breadcrumb.triangle li {
    position: relative;
    padding: 0;
    margin: 4px 4px 4px 0;
  }
  .cd-breadcrumb.triangle li:last-of-type {
    margin-right: 0;
  }
  .cd-breadcrumb.triangle li > * {
    position: relative;
    padding: 1em .8em 1em 2.5em;
    color: #2c3f4c;
    background-color: #edeff0;
    /* the border color is used to style its ::after pseudo-element */
    border-color: #edeff0;
  }
  .cd-breadcrumb.triangle li.current > * {
    /* selected step */
    color: #ffffff;
    background-color: #96c03d;
    border-color: #96c03d;
  }
  .cd-breadcrumb.triangle li:first-of-type > * {
    padding-left: 1.6em;
    border-radius: .25em 0 0 .25em;
  }
  .cd-breadcrumb.triangle li:last-of-type > * {
    padding-right: 1.6em;
    border-radius: 0 .25em .25em 0;
  }
  .no-touch .cd-breadcrumb.triangle a:hover {
    /* steps already visited */
    color: #ffffff;
    background-color: #2c3f4c;
    border-color: #2c3f4c;
  }
  .cd-breadcrumb.triangle li::after, .cd-breadcrumb.triangle li > *::after {
    /* 
    	li > *::after is the colored triangle after each item
    	li::after is the white separator between two items
    */
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    content: '';
    height: 0;
    width: 0;
    /* 48px is the height of the <a> element */
    border: 24px solid transparent;
    border-right-width: 0;
    border-left-width: 20px;
  }
  .cd-breadcrumb.triangle li::after {
    /* this is the white separator between two items */
    z-index: 1;
    -webkit-transform: translateX(4px);
    -moz-transform: translateX(4px);
    -ms-transform: translateX(4px);
    -o-transform: translateX(4px);
    transform: translateX(4px);
    border-left-color: #ffffff;
    /* reset style */
    margin: 0;
  }
  .cd-breadcrumb.triangle li > *::after {
    /* this is the colored triangle after each element */
    z-index: 2;
    border-left-color: inherit;
  }
  .cd-breadcrumb.triangle li:last-of-type::after, .cd-breadcrumb.triangle li:last-of-type > *::after {
    /* hide the triangle after the last step */
    display: none;
  }
  .cd-breadcrumb.triangle.custom-separator li::after {
    /* reset style */
    background-image: none;
  }
  .cd-breadcrumb.triangle.custom-icons li::after, .cd-breadcrumb.triangle.custom-icons li > *::after {
    /* 50px is the height of the <a> element */
    border-top-width: 25px;
    border-bottom-width: 25px;
  }

  @-moz-document url-prefix() {
    .cd-breadcrumb.triangle li::after,
    .cd-breadcrumb.triangle li > *::after {
      /* fix a bug on Firefix - tooth edge on css triangle */
      border-left-style: dashed;
    }
  }
}

// range picker

.range-1 {
  --thumb-color: #ef3e36;
  --thumb-behind-color: #FDB89F;
}

.range-2 {
  --thumb-color: #17BEBB;
  --thumb-behind-color: #83F9F8;
}

.range-3 {
  --thumb-color: #f28123;
  --thumb-behind-color: #fbc466;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}
  
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  border: 1px solid var(--tracker-color);
  overflow: hidden;
}

input[type="range"]::-webkit-slider-thumb {
  height: 12px;
  width: 45px;
  cursor: pointer;
  -webkit-appearance: none;
  border-bottom: 1px solid var(--thumb-color);
  box-shadow: 0 0 0 var(--thumb-behind-color), -40px 0 0 var(--thumb-behind-color), -85px 0 0 var(--thumb-behind-color), -130px 0 0 var(--thumb-behind-color),
      -175px 0 0 var(--thumb-behind-color), -220px 0 0 var(--thumb-behind-color), -265px 0 0 var(--thumb-behind-color), -310px 0 0 var(--thumb-behind-color),
      -350px 0 0 var(--thumb-behind-color), -390px 0 0 var(--thumb-behind-color), -409px 0 0 var(--thumb-behind-color);
  background: var(--thumb-color);
}

datalist {
  display: flex;
  justify-content: space-between;
}

datalist option {
  position: relative;
  background: white;
  top: 18px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  min-height: 13px;
}

datalist option:first-of-type {
  visibility: hidden;
}

datalist option:last-of-type {
  border-right: 0;
}

// loader

.sk-cube-grid {
  width: 80px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}

// loader end
// spinner
@keyframes spinner-animation { 100% { transform: rotate(360deg) } }
// end spinner
.cmErrorMsg>.form-group>.invalid-feedback{
  margin-left: 10px !important;
}
.cmAlignment{
  text-align: right !important;
}
.cmCardHeight{
  min-height: 172px !important;
}
.cmBx28{
  font-size: 28px;
}
.cmBorder{
  border-bottom:1px solid #ccc;
}
.cmDetails{
  font-size: 14px;
  margin-top: 10px;
}
.cmAlignRight{
  text-align: right;
}
.text-bold{
  font-weight: 600;
}
.cmBgli{
  background-color: #e9e9e9;
  width: auto;
  padding: 20px !important;
  margin: 10px;
}